import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import PlatformName from '../../../components/platform-name/PlatformName';
import Button from '../../../components/buttons/button/Button';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';

export default function PlatformConnectCard({ platformName, text, disabled = false }) {
  const { t } = useTranslation(['settings']);

  return (
    <div className={classnames('platform-card', 'platform-connect-card', { disabled })}>
      <PlatformName platform={platformName} />
      <div className="platform-connect-card-text">{text}</div>
      <OrganizationAwareLink
        to={`/settings/platforms/${kebabCase(platformName)}/create?from=Platforms`}
        disabled={disabled}
      >
        <Button
          isFilled
          text={disabled ? t('platforms.cards.contactUs') : t('platforms.cards.connect')}
          disabled={disabled}
        />
      </OrganizationAwareLink>
    </div>
  );
}
