import { chain, get, isNil, startCase, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { mapDate } from '../../../services/MapperUtils';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { getLogo } from '../../../services/LogoService';
import { DATA_ACCESS } from '../../../consts/tagConsts';
import Tags from '../../../components/tags/Tags';
import ApplicationType from '../../../components/application-type/ApplicationType';
import { getConnectorIcon } from '../../data-source/connector-icons/connectorsIconsService';
import PersonData from '../../../components/person-data/PersonData';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import { checkIsCopilot } from '../../../services/utils';
import { ApplicationStatusEnum } from './consts';

function FlowTrigger({ extraData = {} }) {
  const { t } = useTranslation(['common', 'single-application']);

  return (
    <div className="application-data flow-trigger">
      <div className="trigger-title">{t('invocationMethod', { ns: 'single-application' })}</div>
      <KeyValueElement
        title={t('connections.connectorType')}
        value={extraData.invocationConnector || t('general.na')}
        img={extraData.invocationConnector ? getConnectorIcon(extraData.invocationConnector) : null}
      />
      <KeyValueElement
        title={t('triggerName', { ns: 'single-application' })}
        value={extraData.invocationName || t('general.na')}
      />
      <KeyValueElement
        title={t('triggerAction', { ns: 'single-application' })}
        value={extraData.invocationAction || t('general.na')}
      />
    </div>
  );
}

function CopilotAppData({ application, environments }) {
  const { t } = useTranslation(['single-application']);

  const prodAppInstances = application.appInstances.filter(
    (appInstance) => environments?.find((env) => env.id === appInstance.environmentId)?.isProduction,
  );
  const instancesToCheck = prodAppInstances.length ? prodAppInstances : application.appInstances;

  function featureValue(path) {
    const values = chain(instancesToCheck)
      .map((appInstance) => get(appInstance, path))
      .filter((value) => !isNil(value))
      .uniq()
      .value();

    // If all values are the same, return it, otherwise return null
    return values.length === 1 ? values[0] : null;
  }

  const authType = featureValue('extraData.authType');
  const genAI = featureValue('extraData.genAI');
  const useGeneralKnowledge = featureValue('extraData.useGeneralKnowledge');
  const minPublishedOn = chain(application.appInstances)
    .map((appInstance) => (appInstance.lastPublishedTime ? new Date(appInstance.lastPublishedTime) : null))
    .compact()
    .min()
    .value();

  return (
    <div className="application-data">
      {authType && <KeyValueElement title={t('applicationData.copilotData.authType')} value={authType} />}
      {!isNil(genAI) && (
        <KeyValueElement title={t('applicationData.copilotData.genAI')} value={<BooleanValue isTrue={genAI} />} />
      )}
      {!isNil(useGeneralKnowledge) && (
        <KeyValueElement
          title={t('applicationData.copilotData.useGeneralKnowledge')}
          value={<BooleanValue isTrue={useGeneralKnowledge} />}
        />
      )}
      {minPublishedOn && (
        <KeyValueElement title={t('applicationData.copilotData.publishedOn')} value={mapDate(minPublishedOn)} />
      )}
    </div>
  );
}

export default function ApplicationData({ application, isFlow, environments, linksBreadcrumbs }) {
  const { t } = useTranslation(['common']);

  const { platformName } = application.account;
  const creationTime = application.appInstances
    .map((instance) => instance.creationTime)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
  const lastModifiedTime = application.appInstances
    .map((instance) => instance.lastPublishedTime)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];

  const tags = application.appInstances.flatMap((instance) => instance.appInstanceTags.map((ait) => ait.tag));

  const dataAccessTags = uniq(tags.filter((tag) => tag.type === DATA_ACCESS).map((tag) => tag.name));

  const businessContexts = chain(application.appInstances)
    .filter((instance) => instance.active)
    .flatMap((instance) => instance.appInstanceBusinessContexts.map((aibc) => aibc.businessContext.name))
    .uniq()
    .value();

  const { owner } = application;
  const status = application.status.toLowerCase();

  return (
    <>
      <div className="application-data">
        <KeyValueElement title={t('general.type')} value={<ApplicationType value={application.type} />} />
        <KeyValueElement title={t('general.platform')} value={platformName} img={getLogo(platformName)} />
        <KeyValueElement
          title={t('general.owner')}
          value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
        />
        <KeyValueElement title={t('general.creationDate')} value={mapDate(creationTime)} />
        <KeyValueElement title={t('general.lastChange')} value={mapDate(lastModifiedTime)} />
      </div>
      <div className="application-data">
        {status !== ApplicationStatusEnum.ACTIVE && (
          <KeyValueElement title={t('general.status')} value={startCase(status)} />
        )}
        {businessContexts.length > 0 && (
          <KeyValueElement
            title={t('general.businessContext')}
            className="block"
            value={
              <Tags tags={businessContexts.map((tag) => ({ name: tag }))} tooltipAnchor="business-context-tooltip" />
            }
          />
        )}
        {dataAccessTags.length > 0 && (
          <KeyValueElement
            title={t('tags.dataAccess')}
            className="block"
            value={<Tags tags={dataAccessTags.map((tag) => ({ name: tag }))} tooltipAnchor="data-access-tooltip" />}
          />
        )}
      </div>
      {checkIsCopilot(application) && <CopilotAppData application={application} environments={environments} />}
      {isFlow && <FlowTrigger extraData={application.appInstances.at(-1).extraData} />}
    </>
  );
}
