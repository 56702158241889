import { PublicClientApplication } from '@azure/msal-browser';
import { MINUTE } from '../consts/timesConsts';

class AzureAuthConfig {
  constructor() {
    this.clientId = '';
    this.tenantId = '';
    this.msalConfig = {
      auth: {
        clientId: this.clientId,
        authority: `https://login.microsoftonline.com/${this.tenantId}`,
        redirectUri: '/',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    };
    this.msalInstance = new PublicClientApplication(this.msalConfig);
  }

  setConfig(clientId, tenantId) {
    const newConfig = {
      ...this.msalConfig,
      auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: '/',
      },
    };
    this.msalInstance = new PublicClientApplication(newConfig);
  }

  getInstance() {
    return this.msalInstance;
  }
}

const azureAuthConfig = new AzureAuthConfig();
export default azureAuthConfig;

export const AzurePermissions = Object.freeze({
  POWER_APPS_SERVICE: 'https://service.powerapps.com/.default',
  POWER_PLATFORM_USERS_APPLY: 'https://api.powerplatform.com/UserManagement.Users.Apply',
});

/*
The order of the requests here is very important.
https://service.powerapps.com/.default:
- is granted to the app registration is Azure
https://api.powerplatform.com/UserManagement.Users.Apply:
- can't be granted to the app registration in Azure, therefore a consent request is needed
- We must ask for consent for this permission because we can't get a consent for it in the Azure portal
 */
export async function getOnboardingToken(instance) {
  const applyResult = await instance.loginPopup({
    scopes: [AzurePermissions.POWER_PLATFORM_USERS_APPLY],
    // force consent. We saw that in incognito mode, if the user is already logged in, the consent is not asked
    // without consent, the token will not have the required permissions
    prompt: 'consent',
  });

  const result = instance.acquireTokenSilent({
    scopes: [AzurePermissions.POWER_APPS_SERVICE],
    account: applyResult.account,
  });

  return result;
}

export function isTokenExpired(token) {
  // take a 2 minutes buffer to avoid token expiration
  return !token || token.expiresOn * 1000 < Date.now() + 2 * MINUTE;
}

const getApplicationScopes = (insight, application) => {
  const predicates = insight?.rule?.predicates?.[0];
  switch (predicates) {
    case 'orphan_application':
      if (application.type === 'Cloud Flow') {
        return [`${insight?.insightData[0]?.environment?.data?.instanceApiUrl}/.default`];
      }
      return ['https://service.powerapps.com/.default'];
    default:
      return ['https://service.powerapps.com/.default'];
  }
};

export const getAzureScopesByInsightScope = (scopeSubType, insight, resource) => {
  switch (scopeSubType) {
    case 'application':
      return getApplicationScopes(insight, resource);
    case 'app_instance':
    case 'connection':
      return ['https://service.powerapps.com/.default'];
    case 'flow':
      return ['https://service.flow.microsoft.com/.default'];
    case 'BI Report':
      return ['https://analysis.windows.net/powerbi/api/.default'];
    default:
      return null;
  }
};
