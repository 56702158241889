import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDefaultEnvironmentsByAccountId, isEnvironmentsLoading } from '../../../redux/selector/environmentsSelector';
import { mapDate } from '../../../services/MapperUtils';
import PlatformName from '../../../components/platform-name/PlatformName';
import { deleteAccount, renameAccount } from '../../../redux/slicers/accountSlicer';
import { getAccountRenameError, getAccountRenameLoading } from '../../../redux/selector/accountsSelector';
import { POWER_PLATFORM } from '../../../consts/platforms';
import { isPermitted } from '../../../redux/selector/authSelector';
import IconWithText from '../../../components/icon-text/IconWithText';
import offIcon from '../../../icons/off_icon.svg';
import EditAccountCredentialsModal from './power-platform/edit-account-modal/EditAccountCredentialsModal';
import AccountCardMenu from './AccountCardMenu';

const AccountStatus = {
  DISCONNECTED: 'Disconnected',
};

export default function AccountCard({ account }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { platformName, creationTime, id, name } = account;
  const environments = useSelector(getDefaultEnvironmentsByAccountId(id));
  const numEnvironmentsDisconnected = environments.filter((env) => env.status === AccountStatus.DISCONNECTED).length;
  const environmentsLoading = useSelector(isEnvironmentsLoading);
  const renameLoading = useSelector(getAccountRenameLoading(account.id));
  const isDisconnected = account.status === AccountStatus.DISCONNECTED;
  const error = environmentsLoading || !environments.length || isDisconnected;
  const renameError = useSelector(getAccountRenameError(id));
  const canEditEnvironments = useSelector(isPermitted('environments:update'));
  const canRenameAccount = useSelector(isPermitted('accounts:rename'));
  const canDeleteAccount = useSelector(isPermitted('accounts:delete'));
  const isPowerPlatform = account.platformName === POWER_PLATFORM;
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const editDisabled = !isPowerPlatform || !canEditEnvironments;

  const [editCredentialsModalOpen, setEditCredentialsModalOpen] = useState(false);

  async function handlePlatformRename(newName) {
    await dispatch(
      renameAccount({
        accountId: id,
        body: { name: newName, auditUserName: user.name, auditUserEmail: user.email },
      }),
    );
  }

  async function handleDeleteAccount() {
    if (canDeleteAccount) {
      await dispatch(
        deleteAccount({
          accountId: id,
          body: { auditUserName: user.name, auditUserEmail: user.email },
        }),
      );
    }
  }

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  }

  return (
    <div className={classnames('platform-card', 'account-card', { error }, { disconnected: isDisconnected })}>
      <div className={classnames('account-card-body', { disconnected: isDisconnected })}>
        <div className="account-card-header">
          <PlatformName
            name={name}
            platform={platformName}
            onRename={handlePlatformRename}
            loading={renameLoading}
            toastSuccessful={!renameError}
            editable={canRenameAccount}
            toastTitle={t(`platforms.accounts.rename${renameError ? 'Error' : 'Success'}`)}
            error={!!numEnvironmentsDisconnected || !environments.length}
            tooltip={
              environments.length
                ? t('platforms.accounts.errors.disconnectedEnvironments', { count: numEnvironmentsDisconnected })
                : t('platforms.accounts.errors.noEnvironments')
            }
          />
          {!editDisabled && (
            <MoreVertIcon
              className={classnames({ clicked: openMenu }, 'more-icon')}
              onClick={(e) => {
                handlePopoverOpen(e, account);
              }}
            />
          )}
          <AccountCardMenu
            openMenu={openMenu}
            anchorEl={anchorEl}
            setOpenMenu={setOpenMenu}
            account={account}
            editDisabled={editDisabled}
            setEditCredentialsModalOpen={setEditCredentialsModalOpen}
            isDisconnected={isDisconnected}
          />
        </div>

        <div className="account-card-props">
          <div className="account-card-props-entry">
            <div className="account-card-props-entry-title">{t('platforms.accounts.creationTime')}</div>
            <div className="account-card-props-entry-value">{mapDate(creationTime)}</div>
          </div>
          <div className="account-card-props-entry">
            <div className="account-card-props-entry-title">{t('platforms.accounts.environments')}</div>
            <div className="env-counts">
              <div className="account-card-props-entry-value">{environments.length} </div>
              <div className="account-card-props-entry-value gray space-before">
                {` / ${account.data?.totalEnvironments}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDisconnected && (
        <div className="account-card-footer">
          <IconWithText img={offIcon} text={t('platforms.accounts.disconnected')} onClick={handleDeleteAccount} />
        </div>
      )}
      {!editDisabled && (
        <EditAccountCredentialsModal
          account={account}
          modalOpen={editCredentialsModalOpen}
          closeModal={() => setEditCredentialsModalOpen(false)}
        />
      )}
    </div>
  );
}
