import Tooltip from '@mui/material/Tooltip';
import { getLogo } from '../../services/LogoService';

import errorIcon from '../../icons/error_icon.svg';
import './platform-name.scss';
import EditableText from '../EditableText/EditableText';
import EllipsisText from '../ellipsis-text/EllipsisText';

export default function PlatformName({
  platform,
  name,
  onRename,
  editable = false,
  loading = false,
  toastSuccessful,
  toastTitle,
  error = false,
  tooltip,
}) {
  const logo = getLogo(platform);

  const nameToUse = name || platform;

  return (
    <div className="platform-name">
      <img src={logo} alt={`${platform} logo`} className="platform-logo" />
      {editable && !error && (
        <EditableText
          text={nameToUse}
          onSubmit={onRename}
          isLoading={loading}
          toastTitle={toastTitle}
          toastSuccessful={toastSuccessful}
        />
      )}
      {error && (
        <div className="error-platform-name">
          {nameToUse}
          <Tooltip title={tooltip} arrow>
            <img src={errorIcon} alt="error" />
          </Tooltip>
        </div>
      )}
      {!editable && !error && <EllipsisText text={nameToUse} />}
    </div>
  );
}
