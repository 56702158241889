import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import IconWithText from '../../../components/icon-text/IconWithText';
import editIcon from '../../../icons/wide_edit_icon.svg';
import roundedIcon from '../../../icons/rounded_icon.svg';
import keyIcon from '../../../icons/key.svg';

export default function AccountCardMenu({
  openMenu,
  anchorEl,
  setOpenMenu,
  account,
  editDisabled,
  setEditCredentialsModalOpen,
  isDisconnected,
}) {
  const { t } = useTranslation(['settings']);

  return (
    <Menu className="menu-items" open={openMenu} anchorEl={anchorEl} onClose={() => setOpenMenu(false)}>
      <MenuItem onClick={() => {}}>
        <OrganizationAwareLink
          to={`/settings/platforms/${kebabCase(account.platformName)}/${account.id}/edit?from=Platforms`}
          disabled={editDisabled}
        >
          <IconWithText img={editIcon} text={t('platforms.accounts.actions.edit')} />
        </OrganizationAwareLink>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setEditCredentialsModalOpen(true);
          setOpenMenu(false);
        }}
      >
        {isDisconnected ? (
          <IconWithText img={roundedIcon} text={t('platforms.accounts.actions.reconnect')} />
        ) : (
          <IconWithText img={keyIcon} text={t('platforms.accounts.actions.credentials')} />
        )}
      </MenuItem>
    </Menu>
  );
}
