import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/button/Button';
import {
  getEnrichmentError,
  getEnrichmentLoading,
  getEnrichmentSuccessStatus,
} from '../../../redux/selector/biSelector';
import Loader from '../../../components/loader/Loader';
import { enrichPowerBiShareWithEntireOrg } from '../../../redux/slicers/biSlicer';
import { getAzureScopesByInsightScope } from '../../../auth/azure-auth-config';

export default function EnrichModal({ closeModal, accountId }) {
  const { t } = useTranslation(['bi', 'common']);
  const { instance } = useMsal();
  const error = useSelector(getEnrichmentError);
  const loading = useSelector(getEnrichmentLoading);
  const success = useSelector(getEnrichmentSuccessStatus);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  async function handleEnrichment() {
    const result = await instance.loginPopup(getAzureScopesByInsightScope('BI Report'));
    dispatch(
      enrichPowerBiShareWithEntireOrg({
        body: {
          accessToken: result.accessToken,
          accountId,
          auditUserEmail: user.email,
          auditUserName: user.name,
        },
      }),
    );
  }

  function renderEnrichment() {
    return (
      <>
        <div className="text-container">
          {t('request.title', { ns: 'bi' })}
          <div className="text">{t('request.description', { ns: 'bi' })}</div>
        </div>
        <div className="buttons-row">
          <Button text={t('general.cancel', { ns: 'common' })} onClick={closeModal} isFilled={false} />
          <Button text={t('general.approve', { ns: 'common' })} onClick={handleEnrichment} isFilled />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="title">{t('enrichTitle', { ns: 'bi' })}</div>
      {loading && <Loader />}
      {error && (
        <div className="error centered">
          <div>{t('enrichmentError', { ns: 'bi' })}</div>
          <div>{error}</div>
          <br />
        </div>
      )}
      {success && (
        <div className="success centered margin">
          <div>{t('enrichmentSuccess', { ns: 'bi' })}</div>
        </div>
      )}
      {!loading && !error && !success && renderEnrichment()}
      {(error || success) && (
        <div className="buttons-row no-margin">
          <Button text={t('general.done', { ns: 'common' })} onClick={closeModal} isFilled />
        </div>
      )}
    </>
  );
}
