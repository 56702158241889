import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { getLogo } from '../../../services/LogoService';
import PersonData from '../../../components/person-data/PersonData';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { getIsGuest } from '../../../redux/selector/authSelector';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';
import ApplicationType from '../../../components/application-type/ApplicationType';
import { ApplicationStatusEnum } from '../application/consts';

export default function InsightAppData({ application, linksBreadcrumbs }) {
  const isGuest = useSelector(getIsGuest);
  const { t } = useTranslation(['common', 'single-insight']);

  const { platformName } = application.account;
  const logo = getLogo(platformName);

  const { owner } = application;
  const status = application.status.toLowerCase();

  return (
    <div className="insight-app-data row">
      <KeyValueElement
        title={t('applications.applicationName')}
        value={
          <EllipsisText
            containerMaxWidth="200px"
            overrideTooltipText={application.name}
            text={
              <OrganizationAwareLink to={`/applications/${application.id}?from=${linksBreadcrumbs}`} disabled={isGuest}>
                {application.name}
              </OrganizationAwareLink>
            }
          />
        }
        isLink={!isGuest}
      />
      <KeyValueElement title={t('general.type')} value={<ApplicationType value={application.type} />} />
      <KeyValueElement title={t('general.platform')} value={platformName} img={logo} />
      {owner && (
        <KeyValueElement
          title={t('general.owner')}
          value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
        />
      )}
      {status !== ApplicationStatusEnum.ACTIVE && (
        <KeyValueElement title={t('general.status')} value={startCase(application.status.toLowerCase())} />
      )}
    </div>
  );
}
