import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@mui/material';
import { isEmail, isUUID } from 'class-validator';
import { useAuth0 } from '@auth0/auth0-react';
import DropdownBox from '../../../components/dropdown-box/DropdownBox';
import { getRules } from '../../../redux/selector/policySelector';
import { fetchRules } from '../../../redux/slicers/policySlicer';
import Button from '../../../components/buttons/button/Button';
import Attributes from './Attributes';
import LargeTextBox from '../../../components/large-text-box/LargeTextBox';
import { createInsight } from '../../../redux/slicers/insightSlicer';
import ActionModal from '../../../components/modal/ActionModal';
import {
  getCreateInsightError,
  isCreateInsightLoading,
  isCreateInsightSuccess,
} from '../../../redux/selector/insightsSelector';
import CreateInsightEnvironmentsDropdown from './CreateInsightEnvironmentsDropdown';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';

const Scopes = Object.freeze({
  APPLICATION: 'Application',
  CONNECTOR: 'Connector',
  CONNECTION: 'Connection',
  USER: 'User',
  GENERIC: 'Generic',
  BI_REPORT: 'BI Report',
});

const MAX_DESCRIPTION_SIZE = 1000;
const MAX_REMEDIATION_SIZE = 2000;

export default function CreateInsightPage() {
  const { t } = useTranslation(['back-office']);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  useEffect(() => {
    dispatch(fetchRules());
  }, []);

  const exitingEnvironments = useSelector(getEnvironments) || [];
  const rules = useSelector(getRules);
  const loading = useSelector(isCreateInsightLoading);
  const success = useSelector(isCreateInsightSuccess);
  const errorMessage = useSelector(getCreateInsightError);

  const [rule, setRule] = useState(null);
  const [scope, setScope] = useState(Scopes.APPLICATION);
  const [resourceId, setResourceId] = useState('');
  const [insightOwner, setInsightOwner] = useState('');
  const [environments, setEnvironments] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [description, setDescription] = useState('');
  const [remediation, setRemediation] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  const [errors, setErrors] = useState({
    resourceId: false,
    insightOwner: false,
    description: false,
    remediation: false,
  });

  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (rule) {
      setDescription(rule.placeholderDescription || '');
    }
  }, [rule]);

  useEffect(() => {
    if (resourceId === '' || isUUID(resourceId)) {
      setErrors((prev) => ({ ...prev, resourceId: false }));
    } else {
      setErrors((prev) => ({ ...prev, resourceId: true }));
    }
  }, [resourceId]);

  useEffect(() => {
    if (insightOwner === '' || isEmail(insightOwner)) {
      setErrors((prev) => ({ ...prev, insightOwner: false }));
    } else {
      setErrors((prev) => ({ ...prev, insightOwner: true }));
    }
  }, [insightOwner]);

  useEffect(() => {
    if ([resourceId, scope, rule, insightOwner, description, remediation].some((field) => !field || field === '')) {
      setSubmitDisabled(true);
    } else if (Object.values(errors).some((field) => field)) {
      setSubmitDisabled(true);
    } else if (attributes.some((attr) => !attr.key || !attr.value)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [resourceId, scope, rule, attributes, insightOwner, description, remediation, errors]);

  return (
    <div className="create-insight-page">
      <div className="resource-container row">
        <DropdownBox
          width={240}
          text={t('createInsight.fields.scope')}
          value={scope}
          selectedId={scope}
          menuItems={Object.values(Scopes).map((s) => ({
            id: s,
            onClick: () => setScope(s),
            text: s,
          }))}
        />
      </div>
      <div className="row">
        <Input
          value={resourceId}
          onChange={(e) => setResourceId(e.target.value)}
          placeholder="Resource ID (UUID)"
          error={errors.resourceId}
        />
      </div>
      <div className="row">
        <CreateInsightEnvironmentsDropdown
          selectedEnvironments={environments}
          environments={exitingEnvironments}
          setEnvironments={setEnvironments}
          error={errors.environments}
        />
      </div>
      <div className="row">
        <Input
          value={insightOwner}
          onChange={(e) => setInsightOwner(e.target.value)}
          placeholder="Insight Owner Email"
          error={errors.insightOwner}
        />
      </div>
      <div className="row">
        <DropdownBox
          width={240}
          text={t('createInsight.fields.rule')}
          value={rule?.name}
          selectedId={rule?.id}
          menuItems={rules.map((r) => ({
            id: r.id,
            onClick: () => setRule(r),
            text: r.name,
          }))}
        />
      </div>
      <div className="row">
        <LargeTextBox
          id="description"
          title="Description"
          message={description}
          setMessage={setDescription}
          messageValid={!errors.description}
          setMessageValid={(valid) => setErrors((prev) => ({ ...prev, description: !valid }))}
          placeholder="Enter Description"
          maxSize={MAX_DESCRIPTION_SIZE}
        />
      </div>
      <div className="row">
        <LargeTextBox
          id="remediation"
          title="Remediation"
          message={remediation}
          setMessage={setRemediation}
          messageValid={!errors.remediation}
          setMessageValid={(valid) => setErrors((prev) => ({ ...prev, remediation: !valid }))}
          placeholder="Enter Remediation"
          maxSize={MAX_REMEDIATION_SIZE}
        />
      </div>
      <div className="attributes-container row">
        <Attributes attributes={attributes} setAttributes={setAttributes} />
      </div>

      <Button disabled={submitDisabled} isFilled text="Submit" onClick={() => setModalOpen(true)} />
      <ActionModal
        id="create-notification-modal"
        title="Are you sure you want to create this insight?"
        onApprove={async () =>
          dispatch(
            createInsight({
              resourceId,
              scope: scope.toLowerCase(),
              ruleId: rule.id,
              insightOwner,
              environments: environments.map((env) => env.id),
              attributes: attributes.reduce((acc, attr) => ({ ...acc, [attr.key]: attr.value }), {}),
              description,
              remediation,
              auditUserName: user.name,
              auditUserEmail: user.email,
            }),
          )
        }
        loading={loading}
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        toastSuccessful={success}
        toastTitle={success ? 'Insight created successfully' : 'Failed to create insight'}
        toastMessage={errorMessage || ''}
      >
        <div>{`By Clicking approve, you'll create an insight with the following details:`}</div>
        <br />
        <div>{`Resource ID: ${resourceId}`}</div>
        <div>{`Scope: ${scope}`}</div>
        <div>{`Rule: ${rule?.name}`}</div>
        <div>{`Insight Owner: ${insightOwner}`}</div>
        <div>{`Environments: ${environments.map((env) => env.name).join(', ')}`}</div>
        <div>{`Attributes: ${JSON.stringify(attributes)}`}</div>
        <div>{`Description: ${description}`}</div>
        <div>{`Remediation: ${remediation}`}</div>
      </ActionModal>
    </div>
  );
}
