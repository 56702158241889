import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { lightGreenColor, lightOrangeColor } from '../../../consts/colors';
import { getHomepageDynamicMttr, isHomepageDynamicMttrLoading } from '../../../redux/selector/homepageSelector';

export default function InsightMTTR({ labels = [], slidingWindowSize = 30 }) {
  const loading = useSelector(isHomepageDynamicMttrLoading);
  const insights = useSelector(getHomepageDynamicMttr);
  const { t } = useTranslation(['homePage', 'common']);

  const resolvedInsight = insights.map((i) => i.resolvedMttr);
  const acknowledgedInsight = insights.map((i) => i.acknowledgedMttr);

  const data = {
    labels: labels.map((label) => format(label, 'dd/MM')),
    datasets: [
      {
        label: t('insights.resolved', { ns: 'common' }),
        data: resolvedInsight,
        borderColor: lightGreenColor,
        width: '2px',
        tension: 0.2,
      },
      {
        label: t('insights.acknowledged', { ns: 'common' }),
        data: acknowledgedInsight,
        borderColor: lightOrangeColor,
        width: '2px',
        tension: 0.2,
      },
    ],
  };

  return (
    <Card
      title={t('securityWidget.insightMTTR')}
      loading={loading}
      tooltip={t('securityWidget.insightMTTRTooltip', { slidingWindowSize })}
    >
      <div className="chart-wrapper">
        <LineChart data={data} id="mttr" />
      </div>
    </Card>
  );
}
