import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAccounts, getAccountsLoading } from '../../../redux/selector/accountsSelector';
import PlatformConnectCard from './PlatformConnectCard';
import AccountCard from './AccountCard';
import Loader from '../../../components/loader/Loader';
import { isPermitted } from '../../../redux/selector/authSelector';

function NewPlatformsMessage({ hasData, message }) {
  const { t } = useTranslation(['settings']);

  return (
    <div className="platforms-settings-welcome">
      <div className="platforms-settings-welcome-title">
        {t(hasData ? `platforms.${message}` : 'platforms.welcomeTitle')}
      </div>
      {!hasData && <div className="platforms-settings-welcome-text">{t('platforms.welcomeMessage')}</div>}
    </div>
  );
}

function PlatformConnections() {
  const { t } = useTranslation(['settings']);
  const hasPermissionToCreate = useSelector(isPermitted('environments:create'));

  return (
    <div className="platforms-settings-connections">
      <PlatformConnectCard
        platformName="Power Platform"
        text={t('platforms.cards.powerPlatform.text')}
        disabled={!hasPermissionToCreate}
      />
      <PlatformConnectCard platformName="UiPath" text={t('platforms.cards.uiPath.text')} disabled />
      <PlatformConnectCard platformName="ServiceNow" text={t('platforms.cards.serviceNow.text')} disabled />
    </div>
  );
}

function AccountCards({ accounts }) {
  return (
    <div className="platforms-settings-accounts">
      {accounts.map((account) => (
        <AccountCard key={account.id} account={account} />
      ))}
    </div>
  );
}

export default function PlatformsSettingsPage() {
  const accounts = useSelector(getAccounts);
  const accountLoading = useSelector(getAccountsLoading);

  return (
    <div className="platforms-settings">
      {accountLoading ? (
        <Loader />
      ) : (
        <>
          <NewPlatformsMessage hasData={accounts.length} message="myPlatforms" />
          {!!accounts.length && <AccountCards accounts={accounts} />}
          <NewPlatformsMessage hasData={accounts.length} message="addNew" />
          <PlatformConnections />
        </>
      )}
    </div>
  );
}
