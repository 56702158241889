import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useMsal } from '@azure/msal-react';
import Markdown from 'react-markdown';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import Button from '../buttons/button/Button';
import CloseableModal from '../modal/ClosableModal';
import {
  getInsightRemediationCompleted,
  getInsightRemediationError,
  getInsightRemediationFailures,
  getInsightRemediationLoading,
} from '../../redux/selector/insightsSelector';
import { getAzureScopesByInsightScope } from '../../auth/azure-auth-config';
import { clearRemediation, remediateInsights } from '../../redux/slicers/insightSlicer';
import { INTERNAL_SERVER_ERROR } from '../../consts/erros';
import { LinkRenderer } from './RemediationUtils';
import OrphanApplicationRemediation from './insight-specific-remediations/OrphanApplicationRemediation';
import { getApplicationByInsight } from '../../redux/selector/applicationSelector';

export default function AutoRemediateModal({
  platform,
  modalOpen,
  setModalOpen,
  remediationActions = '',
  scopeSubType,
  isDemo,
  insights,
}) {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { user } = useAuth0();
  const remediationSuccesses = useSelector(getInsightRemediationCompleted);
  const isLoading = useSelector(getInsightRemediationLoading);
  const remediationFailures = useSelector(getInsightRemediationFailures);
  const error = useSelector(getInsightRemediationError);
  const application = useSelector(getApplicationByInsight(insights[0]));
  const [authError, setAuthError] = useState(null);
  const remediationSuccess = !isEmpty(remediationSuccesses);
  const { t } = useTranslation(['remediation', 'common']);

  const errorMessage = authError || remediationFailures[0]?.error;

  const remediationCompleted = remediationSuccesses.length + remediationFailures.length;
  const progress = (remediationCompleted / insights.length) * 100;

  function onClose() {
    setModalOpen(false);
    dispatch(clearRemediation());
    setAuthError(null);
  }

  async function handleRemediate({ extraData }) {
    try {
      // todo: remove when applied to all insights with remediationActions
      if (isDemo) {
        setModalOpen(false);
      } else {
        const scopes = getAzureScopesByInsightScope(scopeSubType, insights[0], application);
        const result = await instance.loginPopup({
          scopes,
        });
        dispatch(
          remediateInsights({
            accessToken: result.accessToken,
            insightIds: insights.map((i) => i.id),
            auditUserName: user.name,
            auditUserEmail: user.email,
            extraData,
          }),
        );
      }
    } catch (e) {
      setAuthError(e.message);
    }
  }

  function renderFailure() {
    return (
      <div className="error">
        {!!remediationFailures.length && (
          <div>{t('remediationResult.remediationFailure', { failed: remediationFailures.length })}</div>
        )}
        <div>{errorMessage}</div>
        {errorMessage === INTERNAL_SERVER_ERROR && (
          <div className="text-button">
            <button type="button" className="link" onClick={handleRemediate}>
              {t('general.pleaseTryAgain', { ns: 'common' })}
            </button>
          </div>
        )}
      </div>
    );
  }

  function renderDefaultRemediation() {
    return (
      <>
        <div className="text-container">
          <div>
            {t('remediationModal.remediationConfirmMessage', { platform })}
            <div className="text">
              {(remediationActions || '').split('\n').map((line) => (
                <Markdown key={line} components={{ a: LinkRenderer }}>
                  {line}
                </Markdown>
              ))}
            </div>
          </div>
        </div>
        <div className="buttons-row">
          <Button text={t('general.cancel', { ns: 'common' })} onClick={onClose} isFilled={false} />
          <Button text={t('general.approve', { ns: 'common' })} onClick={handleRemediate} isFilled />
        </div>
      </>
    );
  }

  function renderRemediation() {
    switch (insights[0].rule.predicates[0]) {
      case 'orphan_application':
        return (
          <OrphanApplicationRemediation
            onClose={onClose}
            handleRemediate={handleRemediate}
            entity={insights[0].data.entityName}
            remediationActions={remediationActions}
          />
        );
      default:
        return renderDefaultRemediation();
    }
  }

  return (
    <CloseableModal open={modalOpen} closeModal={onClose}>
      <div className="title">{t('remediationModal.title')}</div>
      {isLoading && (
        <>
          <Loader />
          {insights.length > 1 && (
            <>
              <LinearProgress variant="determinate" value={progress} />
              <div className="center">
                {t('remediationResult.loaderMessage', {
                  completed: remediationCompleted,
                  count: insights.length,
                })}
              </div>
            </>
          )}
        </>
      )}
      {errorMessage && (
        <div className="error centered">
          <div className="remediation-emoji">🩹</div>
          {renderFailure()}
        </div>
      )}
      {!!(progress === 100 && remediationSuccess) && (
        <div className="success centered">
          <div className="remediation-emoji">🏆</div>
          <div>
            {t('remediationResult.remediationSuccess', {
              completed: remediationSuccesses.length,
              count: insights.length,
            })}
          </div>
          {remediationFailures.length > 0 && renderFailure()}
        </div>
      )}
      {!isLoading && !errorMessage && !remediationSuccess && renderRemediation()}
      {(error || remediationSuccess) && (
        <div className="buttons-row">
          <Button text={t('general.done', { ns: 'common' })} onClick={onClose} isFilled />
        </div>
      )}
    </CloseableModal>
  );
}
