import { useState } from 'react';
import Arrow from '../../../components/arrow/Arrow';
import MultiSelectDropdownButton, { CheckType } from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import SimpleError from '../../../components/simple-error/SimpleError';

export default function CreateInsightEnvironmentsDropdown({
  selectedEnvironments,
  environments,
  setEnvironments,
  error,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function toggleEnvironmentsDropdown(e) {
    setAnchorEl(anchorEl ? null : e.currentTarget.children[1]);
  }

  function mapEnvironmentOption(environment) {
    return {
      id: environment.id,
      label: environment.name,
      isSelected: selectedEnvironments.some((env) => env.id === environment.id) ?? false,
    };
  }

  function handleSelect(id, isSelected) {
    if (isSelected) {
      setEnvironments([...selectedEnvironments, environments.find((env) => env.id === id)]);
    } else {
      setEnvironments(selectedEnvironments.filter((env) => env.id !== id));
    }
  }

  return (
    <>
      <div>Environments</div>
      <div className="dropdown-box" onClick={toggleEnvironmentsDropdown}>
        <div className="text">
          {selectedEnvironments.length
            ? environments
                .filter((env) => selectedEnvironments.some((selectedEnv) => selectedEnv.id === env.id))
                .map((env) => env.name)
                .join(', ')
            : 'Select Environments'}
        </div>
        <Arrow value={anchorEl ? 'up' : 'down'} />
      </div>
      <MultiSelectDropdownButton
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        handleClose={toggleEnvironmentsDropdown}
        items={environments.map(mapEnvironmentOption)}
        handleSelect={handleSelect}
        isSortBySelected={false}
        checkType={CheckType.CHECKMARK}
      />
      <SimpleError error={error} dataTestId="input-error-message" />
    </>
  );
}
