import { useEffect, useMemo, useState } from 'react';
import { chain, sum } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '../../components/table/Table';
import {
  fetchApplicationFilterOptions,
  fetchApplicationGroupedCounters,
  updateApplicationFiltersAndRefetchSummaries,
} from '../../redux/slicers/applicationSlicer';
import {
  getApplicationFilters,
  getApplicationFiltersOptions,
  getApplicationsGroupsCounters,
  getApplicationSummaries,
  getTotalApplicationsCount,
  isApplicationsGroupsCountersLoading,
  isApplicationsLoading,
} from '../../redux/selector/applicationSelector';
import { mapDataAccess, mapDate } from '../../services/MapperUtils';
import PlatformName from '../../components/platform-name/PlatformName';
import ColoredBox from '../../components/box/ColoredBox';
import BooleanValue from '../../components/boolean-value/BooleanValue';
import PersonName from '../../components/person-name/PersonName';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import emptyApplicationsImg from './empty_applications.svg';
import WidgetBar from '../../components/widget-bar/WidgetBar';
import DoughnutChart, { OthersLabel } from '../../components/chart/doughnut/DoughnutChart';
import HorizontalBarChart from '../../components/chart/horizontal-bar/HorizontalBarChart';
import PageHeader from '../../components/page-header/PageHeader';
import {
  blues,
  falseColor,
  trueColor,
  lightBlueColor,
  lightGreenColor,
  lightOrangeColor,
  lightPurpleColor,
} from '../../consts/colors';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';
import Tags from '../../components/tags/Tags';
import ApplicationType from '../../components/application-type/ApplicationType';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';
import { ColumnType } from '../../components/table/ColumnOptions';

export default function ApplicationsPage() {
  const [currentFilters, setCurrentFilters] = useState();
  const loading = useSelector(isApplicationsLoading);
  const applicationSummaries = useSelector(getApplicationSummaries);
  const applicationFilterOptions = useSelector(getApplicationFiltersOptions);
  const totalApplicationsCount = useSelector(getTotalApplicationsCount);
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'applications']);

  const groupsLoading = useSelector(isApplicationsGroupsCountersLoading);
  const groups = useSelector(getApplicationsGroupsCounters);

  const sortBy = [
    {
      id: 'Application Name',
      desc: false,
    },
  ];

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '25%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.name,
        Cell: ({ row, value }) => (
          <div className="auto-width">
            <OrganizationAwareLink to={`/applications/${row.original.id}?from=Applications`}>
              <EllipsisText text={value} />
            </OrganizationAwareLink>
          </div>
        ),
      },
      {
        backendKey: 'type',
        width: '15%',
        type: ColumnType.STRING,
        accessor: ({ type }) => type,
        Cell: ({ value }) => <ApplicationType value={value} />,
      },
      {
        backendKey: 'accountName',
        width: '13%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value, row }) => (
          <EllipsisText
            text={<PlatformName platform={row.original.platform} name={value} />}
            overrideTooltipText={value}
          />
        ),
      },
      {
        backendKey: 'owner',
        width: '14%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.owner,
        Cell: ({ value }) => <PersonName name={value} shouldEllipsis />,
        sortType: (rowA, rowB) => {
          const a = (rowA.original.owner ?? '').toLowerCase();
          const b = (rowB.original.owner ?? '').toLowerCase();
          return a.localeCompare(b);
        },
      },
      {
        backendKey: 'createdAt',
        width: '15%',
        type: ColumnType.DATE,
        accessor: (cell) => cell.createdAt,
        Cell: ({ value }) => <div className="gray">{mapDate(value)}</div>,
        sortType: (rowA, rowB) => {
          const a = new Date(rowA.original.createdAt).getTime();
          const b = new Date(rowB.original.createdAt).getTime();
          return a - b;
        },
      },
      {
        backendKey: 'lastChange',
        width: '15%',
        type: ColumnType.DATE,
        accessor: (cell) => cell.lastChange,
        Cell: ({ value }) => <div className="gray">{mapDate(value)}</div>,
        sortType: (rowA, rowB) => {
          const a = new Date(rowA.original.lastChange).getTime();
          const b = new Date(rowB.original.lastChange).getTime();
          return a - b;
        },
      },
      {
        backendKey: 'environments',
        width: '15%',
        accessor: (cell) => cell.environments,
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = value.map((tag) => ({ name: tag }));
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.original.id}`} />;
        },
      },
      {
        backendKey: 'inProduction',
        width: '12%',
        type: ColumnType.BOOLEAN,
        accessor: (cell) => cell.inProduction,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
      {
        backendKey: 'dataAccess',
        width: '13%',
        accessor: (cell) => {
          const { platform, dataAccessTags } = cell;
          return mapDataAccess({ platform, tagNames: dataAccessTags });
        },
        Cell: ({ row, value }) => {
          const noValue = ['Untagged', 'Unknown'].includes(value);
          if (noValue) {
            return <div>{t(`tags.${value.toLowerCase()}`)}</div>;
          }
          const tags = value.split(',').map((tag) => ({ name: tag.trim(), isDark: true }));
          return <Tags tags={tags} tooltipAnchor={`${row.id}-access-tags`} extraTagsDark />;
        },
      },
      {
        backendKey: 'businessContextName',
        width: '15%',
        accessor: (cell) => {
          const { businessContextTags } = cell;
          return (businessContextTags.length ? businessContextTags : ['Unknown']).join(', ');
        },
        Cell: ({ value, row }) => {
          const noValue = value === 'Unknown';
          if (noValue) {
            return <div>{t('tags.unknown')}</div>;
          }
          const tags = value.split(',').map((tag) => ({ name: tag.trim(), isDark: false }));
          return <Tags tags={tags} extraMargin={10} tooltipAnchor={`${row.id}-context-tags`} extraTagsDark={false} />;
        },
      },
      {
        Header: t('insights.insights'),
        backendKey: 'insights',
        width: '10%',
        type: ColumnType.NUMBER,
        accessor: (cell) =>
          chain(cell.insights)
            .values()
            .map((byType) => Object.values(byType))
            .flatten()
            .sum()
            .value(),
        Cell: ({ row, cell }) => {
          const vulnerabilities = sum(Object.values(row.original.insights.vulnerability)) ?? 0;
          const malicious = sum(Object.values(row.original.insights.malicious)) ?? 0;
          return (
            <OrganizationAwareLink to={`/insights?scopeId:is=${cell.row.original.scopeId}&resolved:is=False`}>
              <ColoredBox
                isFilled
                text={cell.value}
                hasBorder={false}
                className="counter-box"
                color={vulnerabilities + malicious > 0 ? 'red' : 'gray'}
              />
            </OrganizationAwareLink>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    (async function fetchData() {
      dispatch(fetchApplicationFilterOptions());
    })();
  }, []);

  useEffect(() => {
    dispatch(fetchApplicationGroupedCounters(currentFilters));
  }, [currentFilters]);

  return (
    <>
      <PageHeader title={t('applicationInventory', { ns: 'applications' })} counter={totalApplicationsCount} />
      <WidgetBar
        boxes={[
          {
            title: t('general.platform'),
            content: <PlatformWidget grouped={groups?.platform} loading={groupsLoading} />,
          },
          {
            title: t('general.type'),
            content: <TypeWidget grouped={groups?.type} loading={groupsLoading} />,
          },
          {
            title: t('general.inProduction'),
            content: <InProductionWidget grouped={groups?.inProduction} loading={groupsLoading} />,
          },
        ]}
      />
      <Table
        totalCount={totalApplicationsCount}
        data={applicationSummaries}
        columns={columns}
        sortBy={sortBy}
        getFiltersSelector={getApplicationFilters}
        type="applications"
        exportPath="application/summary/csv"
        exportFileName="Nokod Applications"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateApplicationFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
          setCurrentFilters(filters);
        }}
        getElementId={(application) => application.id}
        filtersOptions={applicationFilterOptions}
        customFilters={[
          {
            Header: t('dependency', { ns: 'applications' }),
            backendKey: 'dependency',
          },
        ]}
        excludeFilters={[]}
        isLoading={loading}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('noApplications', { ns: 'applications' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="name"
        defaultSearchColumnName={t('tables.applications.columns.name')}
      />
    </>
  );
}

function TypeWidget({ grouped = [], loading }) {
  if (!grouped?.length) {
    return null;
  }

  const sorted = [...grouped].sort((a, b) => b.value - a.value);
  const top3 = sorted.slice(0, 3);
  const others = sorted.slice(3);
  const othersSum = others.reduce((acc, item) => acc + Number(item.value), 0);
  const formattedData = othersSum ? [...top3, { key: OthersLabel, value: othersSum }] : top3;

  const data = {
    labels: formattedData?.map((platform) => platform.key),
    datasets: [
      {
        data: formattedData?.map(({ value }) => Number(value)),
        backgroundColor: [lightGreenColor, lightBlueColor, lightPurpleColor, lightOrangeColor],
        borderWidth: 3,
      },
    ],
  };

  return (
    <DoughnutChart
      id="applicationType"
      data={data}
      loading={loading}
      tooltipDark={false}
      navigatePath="/applications"
      navigateQueryParam="type:is"
    />
  );
}

function InProductionWidget({ grouped, loading }) {
  const { t } = useTranslation(['common']);

  let dataset = [];
  if (grouped?.length) {
    const inProduction = grouped.find((item) => item.key === '1')?.value || 0;
    const notInProduction = grouped.find((item) => item.key === '0')?.value || 0;
    dataset = [Number(notInProduction), Number(inProduction)];
  }

  const data = {
    labels: [t('general.false'), t('general.true')],
    datasets: [
      {
        data: dataset,
        backgroundColor: [falseColor, trueColor],
        borderWidth: 3,
      },
    ],
  };

  return data.datasets[0].data.length ? (
    <DoughnutChart
      id="inProduction"
      data={data}
      loading={loading}
      tooltipDark={false}
      navigatePath="/applications"
      navigateQueryParam="inProduction:is"
    />
  ) : (
    <div />
  );
}

function PlatformWidget({ grouped = [], loading }) {
  const data = grouped?.map((item, index) => ({
    name: item.key,
    value: item.value,
    color: blues[(index * 2) % blues.length],
  }));

  return (
    <HorizontalBarChart data={data} loading={loading} navigatePath="/applications" navigateQueryParam="platform:is" />
  );
}
