import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { lightBlueColor, lightGreenColor } from '../../../consts/colors';
import { getHomepageDynamicMttr, isHomepageDynamicMttrLoading } from '../../../redux/selector/homepageSelector';

export default function InsightTrends({ labels = [] }) {
  const insights = useSelector(getHomepageDynamicMttr);
  const loading = useSelector(isHomepageDynamicMttrLoading);
  const { t } = useTranslation(['homePage']);

  const newInsightsValues = insights.map((i) => i.created);
  const closedInsightsValues = insights.map((i) => i.resolved + i.acknowledged);

  const data = {
    labels: labels.map((label) => format(label, 'dd/MM')),
    datasets: [
      {
        label: t('securityWidget.newInsights'),
        data: newInsightsValues,
        borderColor: lightBlueColor,
        width: '2px',
        tension: 0.2,
      },
      {
        label: t('securityWidget.closedInsights'),
        data: closedInsightsValues,
        borderColor: lightGreenColor,
        width: '2px',
        tension: 0.2,
      },
    ],
  };

  return (
    <Card title={t('securityWidget.insightTrends')} loading={loading}>
      <div className="chart-wrapper">
        <LineChart data={data} id="insight-trends" />
      </div>
    </Card>
  );
}
