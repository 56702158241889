import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchAppChanges,
  fetchApplicationByPlatform,
  fetchBusinessContexts,
  fetchDevelopersWorkload,
  fetchDynamicInsightSummariesForMttr,
  fetchHomepageAggregations,
  fetchHomePageGroupedInsights,
  fetchHomepageInsightSummaries,
  fetchHomepageTenantData,
  fetchUnattendedApplications,
  resetState,
} from '../../redux/slicers/homepageSlicer';
import Card from '../../components/card/Card';
import ApplicationChanges from './application-bar/ApplicationChanges';
import ApplicationsByPlatform from './application-bar/ApplicationsByPlatform';
import NewApplicationChart from './application-bar/NewApplicationChart';
import { fetchRules } from '../../redux/slicers/policySlicer';
import OpenInsights from './security-bar/OpenInsights';
import InsightDataCounters from './security-bar/InsightDataCounters';
import InsightTrends from './security-bar/InsightTrends';
import InsightMTTR from './security-bar/InsightMTTR';
import UnattendedApplicationsTable from './tables/UnattendedApplicationsTable';
import EnvironmentPosture from './security-bar/EnvironmentPosture';
import UnattendedInsightsTable from './tables/UnattendedInsightsTable';
import DeveloperWorkloadTable from './tables/DeveloperWorkloadTable';
import TopAggregationsTable from './tables/TopAggreationsTable';
import { AFTER, IS } from '../../components/filter/filterConsts';
import { StyledSwitch } from '../../components/StyledSwitch';
import DropdownBox from '../../components/dropdown-box/DropdownBox';
import { DAY } from '../../consts/timesConsts';
import BusinessContextTable from './tables/BusinessContextTable';
import VulnerableInsightsTable from './tables/VulnerableInsightsTable';
import { getTenantId } from '../../services/AuthService';
import ScanDataContainer from './system-bar/ScanDataContainer';
import RulesCounter from './system-bar/RulesCounter';
import TotalApplicationsCounter from './application-bar/TotalApplicationsCounter';
import HomePageInsightsSeverityWidget from './system-bar/HomePageInsightsSeverityWidget';
import { getAccounts } from '../../redux/selector/accountsSelector';
import Loader from '../../components/loader/Loader';
import { getOrganizations } from '../../redux/selector/authSelector';

import './home-page.scss';
import { getEnvironments } from '../../redux/selector/environmentsSelector';
import { EnvironmentType } from '../settings/platforms/power-platform/EnvironmentType';

function getLabelDates(totalDays) {
  const labels = [];
  for (let i = 0; i <= totalDays; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    date.setHours(0, 0, 0, 0);
    labels.push(date);
  }
  return labels.reverse();
}

function getTimesLabel(startDate) {
  const inputDateFormat = new Date(startDate);
  const today = new Date();

  const inputDay = inputDateFormat.getDate();
  const inputMonth = inputDateFormat.toLocaleString('default', {
    month: 'short',
  });
  const inputYear = inputDateFormat.getFullYear();

  const todayDay = today.getDate();
  const todayMonth = today.toLocaleString('default', { month: 'short' });
  const todayYear = today.getFullYear();

  // Check if the dates are in the same month and year
  if (inputYear === todayYear && inputMonth === todayMonth) {
    return `${inputMonth} ${inputDay}-${todayDay} ${inputYear}`;
  }
  if (inputYear === todayYear) {
    return `${inputMonth} ${inputDay} - ${todayMonth} ${todayDay} ${inputYear}`;
  }
  return `${inputMonth} ${inputDay} ${inputYear} - ${todayMonth} ${todayDay} ${todayYear}`;
}

const TIME_FRAMES = [7, 30, 90, 365];
const DEFAULT_TIME_WINDOW = 30;

const MTTR_DAYS_WINDOW = 30;

function getTimeFrameInitialValue(timeFrameParam) {
  if (timeFrameParam) {
    const timeFrameAsInt = parseInt(timeFrameParam, 10);
    if (TIME_FRAMES.includes(timeFrameAsInt)) {
      return timeFrameAsInt;
    }
  }
  return DEFAULT_TIME_WINDOW;
}

function getInProductionInitialValue(inProductionParam, environments) {
  if (inProductionParam) {
    return inProductionParam === 'true';
  }

  const defaultEnvironments = (environments || []).filter((env) => env.type === EnvironmentType.Default);

  if (!defaultEnvironments?.length) {
    return true;
  }

  return defaultEnvironments.some((env) => env.isProduction);
}

export default function HomePage() {
  const accounts = useSelector(getAccounts);
  const environments = useSelector(getEnvironments);
  const organizations = useSelector(getOrganizations);

  const { t } = useTranslation(['homePage']);
  const [searchParams, setSearchParams] = useSearchParams();

  const inProductionParam = searchParams.get('inProduction');
  const inProductionInitialState = getInProductionInitialValue(inProductionParam, environments);
  const [showOnlyInProduction, setShowOnlyInProduction] = useState(inProductionInitialState);

  const timeFrameParam = searchParams.get('timeFrame');
  const timeFrameInitialState = getTimeFrameInitialValue(timeFrameParam);
  const [timeFrame, setTimeFrame] = useState(timeFrameInitialState);

  const dispatch = useDispatch();

  const labels = getLabelDates(timeFrame);

  const startTime = Date.now() - timeFrame * DAY;
  const startDate = moment(startTime).format('MM/DD/YYYY');
  const timeRangeLabel = getTimesLabel(startDate);

  const tenantId = getTenantId();

  useEffect(() => {
    setShowOnlyInProduction(getInProductionInitialValue(inProductionParam, environments));
  }, [environments]);

  // fetch dynamic
  useEffect(() => {
    (async function fetchDynamicData() {
      const insightFilters = [
        {
          column: 'creationTime',
          type: AFTER,
          value: startDate,
        },
        {
          column: 'active',
          type: IS,
          value: 'true',
        },
      ];
      if (showOnlyInProduction) {
        insightFilters.push({
          column: 'inProduction',
          type: IS,
          value: showOnlyInProduction,
        });
      }

      dispatch(fetchHomePageGroupedInsights(insightFilters));
      dispatch(
        fetchDynamicInsightSummariesForMttr({
          startDate,
          inProduction: showOnlyInProduction,
        }),
      );
      dispatch(fetchAppChanges({ since: startDate, inProductionOnly: showOnlyInProduction }));
      dispatch(fetchHomepageTenantData({ startDate }));
    })();
  }, [timeFrame, showOnlyInProduction, tenantId, organizations]);

  // fetch static
  useEffect(() => {
    (async function fetchStaticData() {
      const insightFilters = [
        {
          column: 'active',
          type: IS,
          value: true,
        },
      ];
      if (showOnlyInProduction) {
        insightFilters.push({
          column: 'inProduction',
          type: IS,
          value: showOnlyInProduction,
        });
      }
      const applicationsFilters = [];
      if (showOnlyInProduction) {
        applicationsFilters.push({
          column: 'inProduction',
          type: IS,
          value: showOnlyInProduction,
        });
      }

      dispatch(fetchHomepageInsightSummaries(insightFilters));
      dispatch(fetchApplicationByPlatform(applicationsFilters));
      dispatch(fetchRules());
      dispatch(fetchDevelopersWorkload());
      dispatch(fetchUnattendedApplications());
      dispatch(fetchHomepageAggregations());
      dispatch(fetchBusinessContexts());
    })();

    return () => {
      dispatch(resetState());
    };
  }, [showOnlyInProduction, tenantId, organizations]);

  if (!accounts.length) {
    return <Loader />;
  }

  return (
    <div className="home-page" data-testid="home-page">
      <div className="home-page-filters">
        <InProductionToggle
          checked={showOnlyInProduction}
          onChange={(val) => {
            setShowOnlyInProduction(val);
            searchParams.set('inProduction', val);
            setSearchParams(searchParams, { replace: true });
          }}
        />
        <DropdownBox
          width={180}
          text={t('menu.timeFrame')}
          value={t('menu.lastCountDays', { count: timeFrame })}
          selectedId={timeFrame}
          menuItems={TIME_FRAMES.map((count) => ({
            id: count,
            onClick: () => {
              setTimeFrame(count);
              searchParams.set('timeFrame', count.toString());
              setSearchParams(searchParams, { replace: true });
            },
            text: t('menu.lastCountDays', { count }),
          }))}
        />
      </div>
      <Card title={t('applicationsWidget.applications')} className="category">
        <div className="content applications-bar">
          <Card className="flex">
            <TotalApplicationsCounter />
          </Card>
          <ApplicationsByPlatform inProduction={showOnlyInProduction} />
          <ApplicationChanges labels={labels} />

          <NewApplicationChart labels={labels} />
        </div>
      </Card>
      <Card title={t('securityWidget.security')} className="category">
        <div className="content security-bar">
          <div className="row">
            <EnvironmentPosture />
            <OpenInsights inProduction={showOnlyInProduction} startDate={startDate} />
            <InsightDataCounters
              datesLabel={timeRangeLabel}
              startDate={startDate}
              inProduction={showOnlyInProduction}
            />
          </div>
          <div className="row">
            <InsightMTTR labels={labels} slidingWindowSize={MTTR_DAYS_WINDOW} />
            <InsightTrends labels={labels} />
            <HomePageInsightsSeverityWidget isProduction={showOnlyInProduction} startDate={startDate} />
          </div>
        </div>
      </Card>
      <Card title={t('systemWidget.system')} className="category">
        <div className="content system-bar">
          <Card className="short flex">
            <RulesCounter />
          </Card>
          <ScanDataContainer timeRangeLabel={timeRangeLabel} labels={labels} />
        </div>
      </Card>
      <div className="row">
        <UnattendedInsightsTable inProduction={showOnlyInProduction} />
        <VulnerableInsightsTable inProduction={showOnlyInProduction} />
      </div>
      <div className="row">
        <TopAggregationsTable />
        <DeveloperWorkloadTable inProduction={showOnlyInProduction} startDate={startDate} />
      </div>
      <div className="row">
        <UnattendedApplicationsTable inProduction={showOnlyInProduction} />
        <BusinessContextTable inProductionOnly={showOnlyInProduction} />
      </div>
    </div>
  );
}

function InProductionToggle({ checked, onChange }) {
  const { t } = useTranslation(['homePage']);

  return (
    <div className="in-production-toggle" data-testid="in-production">
      <StyledSwitch checked={checked} onChange={() => onChange(!checked)} />
      <div className="text">{t('menu.productionOnly')}</div>
    </div>
  );
}
