export const getHomepageStaticInsights = (state) => state.homepage.static.insights.content;

export const getHomepageDynamicMttr = (state) => state.homepage.dynamic.mttr.content;
export const isHomepageDynamicMttrLoading = (state) => state.homepage.dynamic.mttr.loading;

export const isHomepageStaticInsightsLoading = (state) => state.homepage.static.insights.loading;

export const getHomepageBusinessContexts = (state) => state.homepage.static.businessContexts.content;
export const isHomepageBusinessContextsLoading = (state) => state.homepage.static.businessContexts.loading;

export const getHomepageScanData = (state) => state.homepage.static.tenantData.scanData;
export const getHomepagePostureData = (state) => state.homepage.static.tenantData.postureData;
export const isHomePageTenantDataLoading = (state) => state.homepage.static.tenantData.loading;

export const getHomePageAggregations = (state) => state.homepage.static.aggregations.content;
export const isHomePageAggregationsLoading = (state) => state.homepage.static.aggregations.loading;
export const getHomePageAppChanges = (state) => state.homepage.static.appChanges.content;

export const isHomePageAppChangesLoading = (state) => state.homepage.static.appChanges.loading;

export const getHomePagePlatformName = (state) => state.homepage.static.platformName.content;
export const isHomePagePlatformNameLoading = (state) => state.homepage.static.platformName.loading;

export const getHomePageDeveloperWorkload = (state) => state.homepage.static.developersWorkload.content;
export const isHomePageDeveloperWorkloadLoading = (state) => state.homepage.static.developersWorkload.loading;

export const getHomePageUnattendedApplications = (state) => state.homepage.static.unattendedApplications.content;
export const isHomePageUnattendedApplicationsLoading = (state) => state.homepage.static.unattendedApplications.loading;

export const getHomePageInsightsByStatus = (state) => state.homepage.dynamic.grouped.content.status || [];
export const getHomePageInsightsByPlatform = (state) => state.homepage.dynamic.grouped.content.platformName || [];
export const getHomePageInsightsBySeverity = (state) => state.homepage.dynamic.grouped.content.severity || [];
export const isHomePageGroupedInsightsLoading = (state) => state.homepage.dynamic.grouped.loading;
